import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { FileDownload } from '@styled-icons/remix-line/FileDownload'
import { Pageview } from '@styled-icons/material-outlined/Pageview'
import { useToast } from '../../../util/hooks/useToast'
import { IconButton } from '../../common/Button'
import { AccordionBox } from '../../common/AccordionBox'
import { useDownloadPvByUrl } from '../../../util/hooks/api/ProjectSettings/useDownloadPvByUrl'
import { Loading } from '../../common/Loading'
import { ErrorBox } from '../../common/ErrorBox'
import { downloadFile } from '../../../util/downloadUtils'
import { getDateStringYM } from '../../../util/Date'
import { useProject } from '../../../util/hooks/api/Project/useProject'
import { useProjectPv } from '../../../util/hooks/api/PageView/useProjectPv'
import { EmbeddedLoading } from '../../common/EmbeddedLoading'
import { ProjectPvInfo, ProjectsPvCountChart } from '../../PvCount/ProjectsPvCountChart'
import { YearMonthSelect } from '../../PvCount/YearMonthSelect'

interface Props {
  projectId: string
  defaultExpanded: boolean
}

export function PvCountPanel({ projectId, defaultExpanded }: Props) {
  const { openToast } = useToast()

  const projectIdNum = Number(projectId)

  const todayYearMonth = getDateStringYM(new Date(), '')
  const [pvYearMonth, setPvYearMonth] = React.useState(todayYearMonth)
  const [pvLimit, setPvLimit] = React.useState(0)
  const [isFirstLoading, setIsFirstLoading] = React.useState(true)

  const {
    data: projectData,
    isLoading: isLoadingProject,
    isError: isProjectError,
  } = useProject({ projectId: projectIdNum })
  const {
    data: pvData,
    isLoading,
    isError,
  } = useProjectPv({
    projectId: projectIdNum,
    yearMonth: pvYearMonth,
  })

  const {
    mutate,
    isLoading: isDownloading,
    isError: isDownloadError,
    error,
  } = useDownloadPvByUrl({
    projectId: projectIdNum,
    options: {
      onSuccess: (downloadUrl: string) => {
        downloadFile(downloadUrl)
        openToast({ message: `ダウンロードしました。` })
      },
    },
  })

  const handleDownload = () => {
    mutate({ targetYearMonth: pvYearMonth })
  }

  const handleChangeYearMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPvYearMonth(e.target.value)
  }

  React.useEffect(() => {
    if (!pvData) return

    // PV上限はAPI再コールしても値変わらないので再フェッチ中に値がundefにならないようにlocalステートで管理
    setPvLimit(pvData.limit)

    // PV上限がセットされれば、次回からのPV-APIの呼び出し中はEmbeddedLoadingのみとする
    setIsFirstLoading(false)
  }, [pvData])

  const projectPv = pvData?.pageViewCount ?? 0
  const teamPv = pvData?.teamPageViewCount ?? 0
  const otherProjectPv = teamPv - projectPv

  const infos: ProjectPvInfo[] = [
    {
      name: projectData?.name ?? '',
      count: projectPv,
    },
  ]
  if (otherProjectPv > 0) {
    infos.push({
      name: 'その他のプロジェクト',
      count: otherProjectPv,
    })
  }

  const fetchedError = isError || isProjectError

  return (
    <AccordionBox title="PV数" icon={<Pageview />} accordionProps={{ defaultExpanded }}>
      <ProjectContainer>
        {((!isError && isFirstLoading) || isLoadingProject || isDownloading) && <Loading />}
        {fetchedError && <ErrorBox>{'PV情報の取得に失敗しました。'}</ErrorBox>}
        {isDownloadError && <ErrorBox>{typeof error === 'string' ? error : 'ダウンロードに失敗しました。'}</ErrorBox>}

        <Item>PV数とは、タグを設置したURLに対してタグ設置時からカウントされるものです。</Item>

        <Item>
          <Label>月間上限PV</Label>
          <div>{`${pvLimit?.toLocaleString()} PV`}</div>
        </Item>

        {/* プロジェクト情報が取得できない場合、年月プルダウンが表示できないため消費PV欄を表示しない */}
        {!isProjectError && (
          <Item>
            <Label>消費PV</Label>
            <YearMonthSelect
              plan={projectData?.team.plan}
              createdAt={projectData?.createdAt}
              onChange={handleChangeYearMonth}
            />
            {!isError && (
              <>
                {!isLoading && projectPv === 0 && <div>消費PVはありません</div>}
                {projectPv > 0 && (
                  <PvValues>
                    <div>{`${projectPv.toLocaleString()} PV`}</div>
                    <PvTeamValue>{`（組織全体 ${teamPv.toLocaleString()} PV）`}</PvTeamValue>
                  </PvValues>
                )}
              </>
            )}
          </Item>
        )}

        {/* プロジェクト別消費PVグラフの高さにLoading表示(見栄えが良いので) */}
        {isLoading && (
          <LoadingArea>
            <EmbeddedLoading />
          </LoadingArea>
        )}
        <PvCountExistsVisibleArea isVisible={!fetchedError && projectPv > 0}>
          <Item>
            <Label>プロジェクト別消費PV</Label>
            <ProjectsPvCountChart infos={infos} teamPv={teamPv} teamPvLimit={pvLimit} targetYearMonth={pvYearMonth} />
          </Item>

          <ButtonContainer>
            <IconButton iconLeft={<FileDownload size={15} />} onClick={handleDownload}>
              CSVダウンロード
            </IconButton>
          </ButtonContainer>
        </PvCountExistsVisibleArea>
      </ProjectContainer>
    </AccordionBox>
  )
}

const ProjectContainer = styled.div`
  width: 100%;
  min-height: 445px; // 期間を変えてもパネルの高さが変わらないよう調整
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const ButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Label = styled.div`
  font-weight: bold;
`

const LoadingArea = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PvValues = styled.div`
  display: flex;
`

const PvTeamValue = styled.div`
  color: ${colors.gray500};
`

const PvCountExistsVisibleArea = styled.div<{ isVisible: boolean }>`
  height: 100%;
  flex-direction: column;
  gap: 32px;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`
